// App.tsx

import React, { useRef, useState, useEffect, useReducer } from 'react';

import { Upload, DatePicker, Table, Menu, Popconfirm, Card, FloatButton, Input, Button, Tabs, message } from 'antd';
import type { UploadProps, MenuProps } from 'antd';

import { SearchOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, KeyOutlined, UploadOutlined, CloudDownloadOutlined, DatabaseOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, TeamOutlined, MessageOutlined, DeleteOutlined} from '@ant-design/icons';

import { Employees } from '../employees/Employees';

import axios from 'axios';

import { SIKE } from '../sike/SIKE';
import { PromptFlow } from '../sike/PromptFlow';

import { marked } from 'marked';

import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';  // Or any other style you prefer
import dayjs from 'dayjs';

import MarketIntelligence from '../../yml/marketintelligence/mi.yaml';
import ForYAML from '../../yml/marketintelligence/for.yaml';
import ForMiYAML from '../../yml/marketintelligence/for_market.yaml';
import SelfOptimise from '../../yml/marketintelligence/self_optimise.yaml';
import SelfOptimisePrompt from '../../yml/marketintelligence/self_optimise_prompt.yaml';
import companyObj from '../../json/ifm.json';

const { Dragger } = Upload;
const { Column } = Table;

const toHashTable = (prompts:any) : any => {
    if(Array.isArray(prompts))
        return prompts.map((prompt: any) => {
            if(prompt.each){
                return { key: prompt.prompt, value: prompt.each.map((x:any)=> {
                    return { key: x.prompt, value: toHashTable(x.output) };
                }) };
            }
            if(typeof prompt.subprompts !== "string" && Array.isArray(prompt.subprompts)){
                return toHashTable(prompt.subprompts.filter((x:any)=>x))
            }
            return { key: prompt.parameters && prompt.parameters[0] ? prompt.parameters[0].value : prompt.prompt, value: prompt.subprompts };
        })
    else{
        return { key: prompts.parameters && prompts.parameters[0] ? prompts.parameters[0].value : prompts.prompt, value: toHashTable(prompts.output) };
    }
}

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

type MenuItem = Required<MenuProps>['items'][number];

const InputLabel: React.FC<any>  = (parent: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(parent.label);
    const [lastValue, setLastValue] = useState(parent.label);

    useEffect(()=>{
        setEditValue(parent.label)
        setLastValue(parent.label)
    },[parent.label])

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    return (
        parent.keyVal === '_search_' ?
            <div 
                style={{ 
                    display: 'flex', 
                    width: '100%', 
                    
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.25)', 
                    margin: '10px 10px 20px 0px' 

                }}>
                <Input
                    style={{
                        marginLeft:'7px', 
                        border: '0px',
                    }}
                    autoFocus
                    onChange={(e) => {
                        e.stopPropagation();
                        parent.setSearch(e.target.value);
                    }}
                />
                <Button
                    type="text"
                    icon={<SearchOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        console.log({
                            id: parent.keyVal,
                            name: editValue
                        })

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_dream_update&context=${parent.context}`,
                            {
                                id: parent.keyVal,
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
            </div>
        :
        isEditing ?
            <div style={{ display: 'flex', width: '100%', padding: '0px !important' }}>
                <Input
                    style={{marginLeft:'7px' }}
                    autoFocus
                    defaultValue={editValue}
                    onChange={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                    }}
                    onPressEnter={(e) => {
                            e.stopPropagation();
                            axios.post(
                                `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                                {
                                    name: editValue
                                },
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                }
                            ).then(res => {
                                setIsEditing(false);
                            });
                        
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        setEditValue(e.target.value);
                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: e.target.value
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            setIsEditing(false);
                        });
                    }}
                />
                <Button
                    type="text"
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setLastValue(editValue);

                        axios.post(
                            `${azure_function_url}/LLMModel?command=context_thread_update&context=${parent.context}&thread=${parent.keyVal}`,
                            {
                                name: editValue
                            },
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            }
                        ).then(res => {
                            console.log(res);
                        }).catch(err => {console.log(err);message.error(err.toString())});
            
                    }}
                />
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditValue(lastValue);
                        setIsEditing(false);
                    }}
                />
            </div>
        :
            <div 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width:'100%',
                    borderRadius: '7px',
                    height: '70px',
                    paddingLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                    borderLeft: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                    backgroundColor: parent.selectedThread === parent.keyVal || parent.selectedPrompt === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
                }}
            >
                {parent.isAddThread ? <></>:parent.menuType === 'threads'?<MessageOutlined/>:<UserOutlined/>}
                <a 
                    style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: parent.keyVal === 'add_thread' || parent.keyVal === 'add_team' ? '' : '10px' }}
                    onClick={(e)=>{

                        if(parent.keyVal === 'add_thread' || parent.keyVal === 'add_team')
                            parent.handleAddClick(e);
                        else if(parent.menuType === 'threads'){
                            parent.setThread(parent.keyVal);
                            parent.setLabel(parent.label);
                        }
                        else{
                            parent.setPrompt(parent.keyVal);
                        }
                        
                    }}
                >
                    {editValue}
                </a>
                {parent.isAddThread ? 
                    <Button
                        type="text"
                        icon={<PlusCircleOutlined style={{ flexShrink: 1 }}/>}
                        onClick={parent.handleAddClick}
                    /> 
                : parent.menuType === 'threads' ?
                    <>
                        <Button
                            type="text"
                            icon={<EllipsisOutlined style={{ flexShrink: 1 }} />}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent the menu from triggering its default behavior
                                setIsEditing(true);
                            }}
                        />
                        { parent.selectedThread === parent.keyVal ? 
                        <Popconfirm
                            title="Delete Thread"
                            description="Would you like to delete the thread?"
                            onConfirm={(e) => {
                                setIsEditing(false);

                                axios.post(
                                    `${azure_function_url}/LLMModel?command=context_thread_delete&context=${parent.context}&thread=${parent.keyVal}`,
                                    {
                                        name: editValue
                                    },
                                    {
                                        headers: { 'Authorization': parent.bearerToken }
                                    }
                                ).then(res => {
                                    setIsEditing(false);
                                    parent.reload();
                                }).catch(err => {console.log(err);message.error(err.toString())});
                            }}
                            onCancel={() => { }}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            
                        />
                        </Popconfirm>
                        :<></>
                        }
                    </>
                :<></>
                }
            </div>
    );
};

const insertItemInTree = (root: any[], parts: string[], item: any, pathSoFar = '') => {
    let currentLevel = root;
    parts.forEach((part, index) => {
        // Update the path so far with the current part
        const currentPath = pathSoFar + (index > 0 ? '/' : '') + part;

        if (index < parts.length - 1) {
            // We are dealing with a directory
            let directory = currentLevel.find(child => child.name === part);
            if (!directory) {
                directory = { name: part, children: [], key: currentPath };
                currentLevel.push(directory);
            }
            currentLevel = directory.children!;
        } else {
            // We are dealing with a file (leaf)
            currentLevel.push({ ...item, name: part, key: currentPath });
        }
    });
};

const transformFileList = (fileList: any[]): any[] => {
    const root: any[] = [];

    fileList.forEach((item) => {
        const parts = item.name.split('/');
        insertItemInTree(root, parts, item);
    });

    return root;
};

export const Knodules: React.FC<any> = (parent) => {

    const [thread, setThread] = useState('-');
    const [label, setLabel] = useState('');
    const [search, setSearch] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('chat'); // Default active tab key
    const [menuType, setMenuType] = useState('threads'); // Default active tab key
    const [prompt, setPrompt] = useState<string>(); // Default active tab key
    const [flows, setFlows] = useState<Record<string,any>>(); // Default active tab key
    const [openKeys, setOpenKeys] = useState(['1']);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [isLoadingFiles, setLoadingFiles] = useState({ state: true});
    const [fileList, setFileList] = useState<any[]>();
    const [documentType, setDocumentType] = useState<string>("8191");

    const [context, setContext] = useState<string>(parent.context);
    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key
    const [openMenu, setOpenMenu] = useState(!parent.isMobile);

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const fileUploadEndRef = useRef<HTMLDivElement | null>(null);

    const [fileUploadMessage, setFileUploadMessage] = useState();

    const loadThreadList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=context_thread_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const it = res.data.map((x:any) => getItem(x.thread === '_flows_' ? 'Workflows' : x.name, x.thread));
                it.unshift(getItem('', '_search_', (e: React.Key) => handleAddOnTop(e, it.length)));
                it.push(getItem('New Thread', 'add_thread', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                forceUpdate();
                
                if(set_thread && res.data.length > 0){
                    setThread(res.data[0].thread);
                    setLabel(res.data[0].name);
                }
            })
            .catch(err => {console.log(err);message.error(err.toString())});
    }

    const loadFlowList = (set_thread = true) => {
        axios.get(
            `${azure_function_url}/LLMModel?command=flow_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                const records = res.data.reduce((acc: Record<string, any>, curr: any) => {
                    const key = String(curr.id); // Ensuring the key is a string
                    acc[key] = curr;
                    return acc;
                }, {});
                setFlows(records);
                const it = res.data.map((x:any) => getItem(x.name, x.id));
                it.push(getItem('New Team', 'add_team', (e: React.Key) => handleAddOnTop(e, it.length)));
                setMenuItems(it);
                if(set_thread && res.data.length > 0 || prompt === undefined)
                   setPrompt(res.data[0]?.data?.id);                
            })
            .catch(err => {console.log(err);message.error(err.toString())});
    }

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        addOnTop?: (key: React.Key) => void,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {   
        // Function to call when the Add Thread button is clicked
        const handleAddClick = (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation(); // Prevent triggering click on menu item
            addOnTop && addOnTop(key);
        };
      
        // If the key is 'add_thread', we want to add an "Add" button instead of an "Edit" button
        const isAddThread = key === 'add_thread' || key === 'add_team';
        
        return {
            key,
            label: <InputLabel search={search} setSearch={setSearch} bearerToken={parent.bearerToken} menuType={menuType} context={context} label={label} keyVal={key} selectedThread={thread} selectedPrompt={prompt} isAddThread={isAddThread} handleAddClick={handleAddClick} setThread={setThread} setLabel={setLabel} setPrompt={setPrompt} reload={loadThreadList}/>,
            type,
        } as MenuItem;
    }
    
    const handleAddOnTop = (key: React.Key,num : number) => {
        if (key === 'add_thread') {
            const name = `Thread ${num}`;
            const id = `${Date.now()}`
            const newItem = getItem(name, id);

            axios.post(
                `${azure_function_url}/LLMModel?command=context_thread_update&context=${context}&thread=${id}`,
                {
                    name: name
                },
                {
                    headers: { 'Authorization': parent.bearerToken }
                }
                )
                .then(res => {
                    setMenuItems((prevItems) => {
                        // Find the index of 'Add Thread'
                        const addThreadIndex = prevItems.findIndex(item => item?.key === 'add_thread');
                        // Insert new item just above 'Add Thread'
                        const newItems = [...prevItems];
                        newItems.splice(addThreadIndex, 0, newItem);
                        return newItems;
                    });

                    setThread(id);
                    setLabel(name);
                }).catch(err => {console.log(err);message.error(err.toString())});       
        }
        else{
            setPrompt('add_');
        }
    };

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    useEffect(() => {
        if(menuType === 'threads')
            loadThreadList(thread === '-');
        else
            loadFlowList(false);

    },[context, thread, prompt, menuType]);

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        accept: '.pdf, .ts, .md, .docx',
        defaultFileList: fileList,
        customRequest: async (options: any) => { 
            
            // eslint-disable-next-line
            const { onSuccess, onError, file, onProgress } = options;
            const buffer = await (file as File).arrayBuffer();
            const bytes = new Uint8Array(buffer);

            const fmData = new FormData();

            setLoadingFiles({ state: true });
    
            fmData.append("file", new Blob([bytes], {type:file.type}), file.name);

            const res = await axios.post(
                `${azure_function_url}/LLMModel?command=files_upload&context=${context}&chunkSize=${documentType}`,
                fmData,
                {
                    maxContentLength: Number.POSITIVE_INFINITY,
                    headers: {
                        "Content-Type": `multipart/form-data; boundery=${(fmData as any)._boundary}`,
                        'Authorization': parent.bearerToken
                    },
                }
            )

            const _id = res.data;

            const timer = setInterval(() => {
                // axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=first`,
                axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=__all__`,
                {
                    headers: { 'Authorization': parent.bearerToken }
                })
                .then((data:any) => {

                    // console.log(data)

                    if(data.data.finished){
                        clearInterval(timer);

                        const waitOneSecond = (): Promise<void> => {
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                }, 1000); // 1000 milliseconds = 1 second
                            });
                        }

                        waitOneSecond()
                        .then(() => {
                            // updateHistory();
                            // clearAllTextAreas();

                            // const files = res.data.map((row: any, i: number) => {
                            //     return {
                            //         uid: i,
                            //         id: row.id,
                            //         name: row.name,
                            //         status: 'done',
                            //         data: row.data,
                            //         added: row.added,
                            //         last_read: row.last_read,
                            //         initialChunkSize: row.initialchunksize
                            //     };
                            // }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                
                            // setLoadingFiles({ state: false });
                            // setFileList(files);

                            axios.get(
                                `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
                                {
                                    headers: { 'Authorization': parent.bearerToken }
                                })
                                .then(res => {
                                    if(res.data){
                                        const files = res.data.map((row: any, i: number) => {
                                            return {
                                                uid: i,
                                                id: row.id,
                                                name: row.name,
                                                status: 'done',
                                                data: row.data,
                                                added: row.added,
                                                last_read: row.last_read,
                                                initialChunkSize: row.initialChunkSize
                                            };
                                        }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                                        setLoadingFiles({ state: false });
                                        setFileList(files);
                                        if(parent.isMobile)
                                            setOpenMenu(false);

                                        setFileUploadMessage(undefined);

                                        setSikeUpdateCounter(x=>x=x+1);
                
                                        if(onSuccess){
                                            onSuccess("Ok");
                                        }   
                                    }
                                    else
                                        setLoadingFiles({ state: false });
                            })
                            .catch(err => {console.log(err);message.error(err.toString())});

                            
                            // setSikeUpdateCounter(x=>x=x+1);
                
                            // if(onSuccess){
                            //     onSuccess("Ok");
                            // }   
                        });
                    }
                    else 
                    // if(data.data.content.trim() !== '')
                    {
                        setFileUploadMessage(data.data.content?? + '...');
                        fileUploadEndRef.current?.scrollIntoView({ behavior: "smooth" });
                        // setLastMessage(data.data.content + '...');
                        // scrollToBottom();
                    }
                });
            }, 1000);

            // const files = res.data.map((row: any, i: number) => {
            //     return {
            //         uid: i,
            //         id: row.id,
            //         name: row.name,
            //         status: 'done',
            //         data: row.data,
            //         added: row.added,
            //         last_read: row.last_read,
            //         initialChunkSize: row.initialchunksize
            //     };
            // }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);

            // setLoadingFiles({ state: false });
            // setFileList(files);
            // setSikeUpdateCounter(x=>x=x+1);

            // if(onSuccess){
            //     onSuccess("Ok");
            // }            
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'removed') {
                message.success(`${info.file.name} file removed successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    
    let items = [
        {
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <MessageOutlined />
                    {thread === '_flows_' ? 'Workflows' : label}
                </span>
                ),
            key: 'chat',
            children:
            <div 
                style={{ 
                    height: 'calc(100vh - 155px)'  
                }}
            >
                <SIKE 
                    bearerToken={parent.bearerToken} 
                    user={parent.user} 
                    context={context} 
                    thread={thread} 
                    permission={parent.permission} 
                    updateCounter={sikeUpdateCounter}
                    isMobile={parent.isMobile}
                    onFinished={() => {
                        axios.get(
                            `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
                            {
                                headers: { 'Authorization': parent.bearerToken }
                            })
                            .then(res => {
                                if(res.data){
                                    const files = res.data.map((row: any, i: number) => {
                                        return {
                                            uid: i,
                                            id: row.id,
                                            name: row.name,
                                            status: 'done',
                                            data: row.data,
                                            added: row.added,
                                            last_read: row.last_read,
                                            initialChunkSize: row.initialChunkSize
                                        };
                                    }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                                    setLoadingFiles({ state: false });
                                    setFileList(files);
                                    if(parent.isMobile)
                                        setOpenMenu(false);

                                    setFileUploadMessage(undefined);

                                    setSikeUpdateCounter(x=>x=x+1);
            
                                     
                                }
                                else
                                    setLoadingFiles({ state: false });
                        })
                        .catch(err => {console.log(err);message.error(err.toString())});
                    }}
                />
            </div>
        },
        {
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <DatabaseOutlined />
                    Data
                </span>
                ),
            key: 'data',
            children: 
                <>
                { fileUploadMessage &&
                    <div 
                    ref={fileUploadEndRef} 
                    style={{ 
                        paddingLeft: parent.isMobile ? undefined : '40px', 
                        paddingRight: parent.isMobile ? undefined : '40px', 
                        fontSize: '18px' ,
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        height: 'calc(100vh - 155px)',
                        overflowY: 'auto'
                    }} 
                    dangerouslySetInnerHTML={{ __html: renderMarkdown(fileUploadMessage) }} 
                />}
                {!fileUploadMessage && <>
                    <Table 
                        dataSource={fileList && transformFileList(fileList)?.map((x, i) => {
                            x.key = i;
                            return x;
                        })}
                        pagination={{hideOnSinglePage: true}}
                        loading={isLoadingFiles.state}
                    >
                        <Column
                            title="Name"
                            key="name"
                            render={(_: any, record: any) => (
                                record.name
                            )}
                        />
                        <Column
                            title="Added"
                            key="added"
                            render={(_: any, record: any) =>
                                (record.added ? <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(Date.parse(record.added) / 1000)} /> : <></>
                            )}
                        />
                        <Column
                            title="Delete"
                            key="delete"
                            render={(_: any, record: any) => (
                                record.added && parent.permission !== 'WORKER' ? 
                                <Button 
                                    type="default" 
                                    block
                                    onClick={async ()=> { 
                                        setLoadingFiles({ state: true });
                                        const res = await axios.get(
                                            `${azure_function_url}/LLMModel?command=files_delete&id=${record.id}&context=${context}`,
                                            {
                                                headers: { 'Authorization': parent.bearerToken },
                                            });

                                        const files = res.data.map((row: any, i: number) => {
                                            return {
                                                uid: i,
                                                id: row.id,
                                                name: row.name,
                                                status: 'done',
                                                data: row.data,
                                                added: row.added,
                                                last_read: row.last_read,
                                                initialChunkSize: row.initialchunksize
                                            };
                                        }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                            
                                        setLoadingFiles({ state: false });
                                        setFileList(files);
                                        setSikeUpdateCounter(x=>x=x+1);
                                    }}
                                >
                                    <DeleteOutlined />
                                </Button>
                                :
                                <></>
                            )}
                        /> 
                        <Column
                            title="Download"
                            key="download"
                            render={(_: any, record: any) => (
                                record.added && parent.permission !== 'WORKER' ? 
                                <Button 
                                    type="default" 
                                    block
                                    onClick={async () => {
                                        const { data } = await axios.get(
                                            `${azure_function_url}/LLMModel?command=files_get&id=${record.id}&context=${context}`,
                                            {
                                                headers: { 'Authorization': parent.bearerToken}
                                            });

                                        const file = new File([Buffer.from(data.data,"base64")], record.name, {type: 'application/octet-stream'});
                                        const element = document.createElement("a");
                                        element.href = URL.createObjectURL(file);
                                        element.download = record.name;
                                        document.body.appendChild(element);
                                        element.click();
                                    }}
                                >
                                    <CloudDownloadOutlined />
                                </Button>
                                :
                                <></>
                            )}
                        />
                    </Table>
                    { parent.permission !== 'WORKER' && (
                    <div style={{height:'250px'}}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag some knowledge</p>
                            <p className="ant-upload-hint">
                            Only .pdf, .ts, .md, .docx files please.
                            </p>
                        </Dragger>      
                    </div> )}
                </>}
                </>
        
    }];

    if(!parent.isMobile){
        items = items.concat({
            label: (
                <span style={{
                    fontSize: parent.isMobile ? undefined : '22px', 
                    fontWeight: 100
                }}>
                    <TeamOutlined />
                    Teams
                </span>
                ),
            key: 'teams',
            children: 
                <div style={{ height:'calc(100vh - 155px)' }}>
                    <PromptFlow bearerToken={parent.bearerToken} updateList={loadFlowList} user={parent.user} context={context} thread={'_flows_'} permission={parent.permission} updateCounter={sikeUpdateCounter} prompt={flows && prompt ? flows[prompt] : undefined} />
                </div>
        });
    }
    
    if(parent.permission !== 'WORKER' && parent.user.id !== context){
        items = items.concat(
            {
                label: (
                    <span style={{
                        fontSize: parent.isMobile ? undefined : '22px', 
                        fontWeight: 100
                    }}>
                        <KeyOutlined />
                        Permissions
                    </span>
                    ),
                key: 'permissions',
                children: 
                    <><Employees user={parent.user} bearerToken={parent.bearerToken} entityId={context} /></>
            }
        );
    }

    useEffect(() => {
        setLoadingFiles({ state: true });

        axios.get(
            `${azure_function_url}/LLMModel?command=files_list&context=${context}`, 
            {
                headers: { 'Authorization': parent.bearerToken }
            })
            .then(res => {
                if(res.data){
                    const files = res.data.map((row: any, i: number) => {
                        return {
                            uid: i,
                            id: row.id,
                            name: row.name,
                            status: 'done',
                            data: row.data,
                            added: row.added,
                            last_read: row.last_read,
                            initialChunkSize: row.initialChunkSize
                        };
                    }).sort((n1: any,n2: any) => n1.name < n2.name ? 1 : -1);
                    setLoadingFiles({ state: false });
                    setFileList(files);
                    if(parent.isMobile)
                        setOpenMenu(false);
                }
                else
                    setLoadingFiles({ state: false });
        })
        .catch(err => {console.log(err);message.error(err.toString())});

    }, [context, thread]);

    useEffect(() => {
        setThread('-');
        setLabel('');
        setSearch('');
    
        setContext(parent.context);   

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: ForYAML,
        //         save: 'false',
        //         async: 'true',
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         const _id = res.data;
        //         console.log(_id)
        //         // console.log(toHashTable(_id.prompts))


        //         const timer = setInterval(() => {
        //             // axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=first`,
        //             axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=__all__`,
        //             {
        //                 headers: { 'Authorization': parent.bearerToken }
        //             })
        //             .then((data:any) => {
        //                 console.log(data.data)
        //                 if(data.data.finished){
        //                     clearInterval(timer);

        //                     // waitOneSecond()
        //                     // .then(() => {
        //                     //     updateHistory();
        //                     //     clearAllTextAreas();
        //                     // });
        //                 }

        //             });
        //         }, 1000);
        //     })

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: MarketIntelligence,
        //         save: 'false',
        //         async: 'false',
        //         parameters: [{
        //             id: "company",
        //             value: "Microsoft"
        //         }]
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         console.log("1", res.data)
        //     })

        console.log("2", toHashTable(companyObj))

        // axios.get(
        //     `${azure_function_url}/LLMModel?command=graph_test`, 
        //     {
        //         headers: {
        //             'Authorization': parent.bearerToken
        //         }
        //     }).then(x => console.log('GRAPH: ', x));

        

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: ForMiYAML,
        //         save: 'false',
        //         async: 'true',
        //         parameters: [{
        //             id: "company",
        //             // value: "NotCentralised Pty Ltd"

        //             // value: "Neu Capital"
        //             // value: "IFM Investors"
        //             value: "https://www.platform1.cx/"
        //             // value: "Partners for Growth"

        //             // value: "Microsoft"
        //         }]
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {

        //         const _id = res.data;
                
        //         const timer = setInterval(() => {
        //             // axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=first`,
        //             axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=__all__`,
        //             {
        //                 headers: { 'Authorization': parent.bearerToken }
        //             })
        //             .then((data:any) => {

        //                 console.log(data.data);
                    
        //                 if(data.data.finished){
        //                     clearInterval(timer);

        //                     // waitOneSecond()
        //                     // .then(() => {
        //                     //     updateHistory();
        //                     //     clearAllTextAreas();
        //                     // });
        //                 }
        //                 else 
        //                 // if(data.data.content.trim() !== '')
        //                 {
        //                     // setLastMessage(data.data.content + '...');
        //                     // scrollToBottom();
        //                 }
        //             });
        //         }, 1000);

        //         console.log("3", res.data.prompts)
        //     })

        // axios.get(
        //     `${azure_function_url}/LLMModel?command=news_vectors&context=${context}`, 
            
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
                

        //         console.log("new vectors")
        //     })

        // axios.get(
        //     `${azure_function_url}/LLMModel?command=copy_test&context=${context}`, 
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
                

        //         console.log(res.data)
        //     })

        // axios.get(
        //     `${azure_function_url}/LLMModel?command=web_vectors&context=5c8d1bf3-30c2-4d02-a651-320d67767902`, 
            
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
                

        //         console.log("new vectors")
        //     })

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: SelfOptimise,
        //         save: 'false',
        //         async: 'false'
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         console.log("3", res.data)
        //     })

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: SelfOptimisePrompt,
        //         save: 'false',
        //         async: 'false'
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         console.log("4", res.data)
        //     })

        // axios.post(
        //     `${azure_function_url}/LLMModel?command=flow`, 
        //     {
        //         model: 'gpt-4',
        //         yaml: MarketIntelligence,
        //         save: 'false',
        //         async: 'false',
        //         parameters: [{
        //             id: "company",
        //             value: "Microsoft"
        //         }]
        //     },  
        //     {
        //         headers: { 'Authorization': parent.bearerToken }
        //     })
        //     .then(res => {
        //         console.log(res.data)
        //     })
        
        // axios.get(
        //     `${azure_function_url}/LLMModel?command=news_update`, 
        //     {
        //         headers: { 'Authorization': parent.bearerToken}
        //     })
        //     .then(x => { 
        //         console.log(x.data);
        //     });
        
        // axios.get(
        //     `${azure_function_url}/LayerC?command=balance&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0`, 
        //     {
        //         headers: {
        //           'Authorization': parent.bearerToken
        //         }
        //     }).then(x => { 
        //         console.log(x.data);
        //         axios.get(
        //             `${azure_function_url}/Hutly?command=test2&chainid=31337&currency=usdc&amount=1000`, 
        //             {
        //                 headers: {
        //                   'Authorization': parent.bearerToken
        //                 }
        //             }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=test3&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=test&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=deposit_amount&chainid=31337&currency=usdc&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=request_credit&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0&amount=1000`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=redeem_credit&chainid=31337&currency=usdc&amount=50`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=withdraw_amount&chainid=31337&currency=usdc&amount=5`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.post(
        //         //     `${azure_function_url}/LayerC?command=send_amount&chainid=31337&currency=usdc&obligor=0x574D1135a10E91006eC937eFD2b29FC5B99F18a0`, 
        //         //     {
        //         //         destination: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',
        //         //         amount: 15,
        //         //     },
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.post(
        //         //     `${azure_function_url}/LayerC?command=retreive_amount&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         idhash: '21112703595377841928034256812283178714763988269873382168309281787118545069511'
        //         //     },
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=sales_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('sales_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=sales_authorities_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('sales_authorities_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=rentals_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('rentals_contracts: ', x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/Hutly?command=rentals_authorities_contracts&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log('rentals_authorities_contracts: ', x.data));
    

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=assets_get&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=liabilities_get&chainid=31337&currency=usdc`, 
        //         //     {
        //         //         headers: {
        //         //             'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));

        //         // axios.post(
        //         // `${azure_function_url}/LayerC?command=create_deal&chainid=31337&currency=usdc`, 
        //         // {
        //         //     counterpart: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',   
        //         //     data: { 
        //         //         // id: '0101',
        //         //         version: '1',
        //         //         type: 'test type',
        //         //         name: 'name 1',
        //         //         description: 'desc 1',
        //         //         some: 'thing' 
        //         //     },
        //         //     initial_payments: [
        //         //         {
        //         //             amount: '100'
        //         //         }, 
        //         //         {
        //         //             amount: '150'
        //         //         }, 
        //         //         // {
        //         //         //     amount: '200'
        //         //         // }, 
        //         //         // {
        //         //         //     amount: '250'
        //         //         // }
        //         //     ]
        //         // },
        //         // {
        //         //     headers: {
        //         //         'Authorization': parent.bearerToken
        //         //     }
        //         // }).then(x => console.log(BigInt(x.data).toString()));

        //         // axios.post(
        //         // `${azure_function_url}/LayerC?command=accept_deal&chainid=31337&currency=usdc`, 
        //         // {
        //         //     // counterpart: '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0',
        //         //     idhash: 9
        //         // },
        //         // {
        //         //     headers: {
        //         //         'Authorization': parent.bearerToken
        //         //     }
        //         // }).then(x => console.log(x.data));

        //         // axios.get(
        //         //     `${azure_function_url}/LayerC?command=deal_get&chainid=31337&id=2`, 
        //         //     {
        //         //         headers: {
        //         //           'Authorization': parent.bearerToken
        //         //         }
        //         //     }).then(x => console.log(x.data));
        //     });
    }, [parent.context]);

    
    return (
        <>
            <div style={{
                position: 'relative', 
                display: 'flex', 
                justifyContent: 'center', 
                height: 'calc(100vh - 65px)'
            }}>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined: 'none',
                        width: parent.isMobile ? '100%' : 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        
                        position: 'sticky',
                        top: parent.isMobile ? undefined : '100px', // Adjust the value as needed to position the menu correctly
                        overflowY: 'auto'
                    }}
                    items={menuItems.filter((x:any)=> x.key === '_search_' || x.key === 'add_dream' || x.label.props.label.toLowerCase().indexOf(search?.toLowerCase()) > -1)}
                />
                <div 
                    className='custom-card' 
                    style={{
                        display: openMenu && parent.isMobile ? 'none' :  undefined,
                        width:  '100%', 
                        height: '100%',
                        marginRight: parent.isMobile ? undefined :  '55px', 
                        marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
                    }}
                >
                    <Card 
                        bordered={false} 
                        className='custom-card'
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Tabs
                            activeKey={activeTabKey}
                            defaultActiveKey="1"
                            onChange={(key) => { 
                                if(key === 'teams')
                                    setMenuType('teams');
                                else
                                    setMenuType('threads');
                                setActiveTabKey(key); 
                            }}
                            type="card"
                            size={parent.isMobile ? "small" : "large"}
                            
                            items={items} 
                        />
                        
                    </Card>
                    
                </div>
                <FloatButton.Group
                    type="default"
                    style={{ 
                        left: 7, 
                        bottom: parent.isMobile ? (openMenu ? 20 : 90) : 20,
                        width: '40px'                  
                    }}
                >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
            </div>
        </>
    );
}